import { initializeApp } from "firebase/app"
import { getFirestore} from 'firebase/firestore';

import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import { appFirebaseConfig } from 'settings';

export const app = initializeApp(appFirebaseConfig)

// getFirestore().settings({ })
export const db = getFirestore(app);

export default app